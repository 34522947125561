import { useEffect } from "react";
const Tasks = (props) => {

    props.sessioninfo.toolBar1Buttons = [];
    useEffect(() => {
        props.sessioninfo.toolBar1State(props.sessioninfo.toolBar1Buttons);
      }, []);
    return (
        <div>Page for Tasks / {props.sessioninfo.menuItems.find(el => el.id === props.obj).descr}!!!</div>
    )
}

export default Tasks;