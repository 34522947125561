import { Routes, Route } from "react-router-dom";
import CentralBox from "../pages/base/centralbox.js";

import WrapperComponent from '../components/wrappercomponent';  
import { useSelector } from "react-redux";

const BaseRoutes = () => {

    const menuItems = useSelector(state => state.mainObject.menuItems);

    return (
        <Routes>
            <Route key="0" path="/*" element={<CentralBox />}/>         
            {menuItems.filter(el => el.form !== '').map(el => (
                <Route key={el.id} path={"/"+el.link} element={<WrapperComponent component={el.form} obj={el.id} descr={el.descr} tablename={el.tablename}/>}/>
            ))}            
        </Routes>
    )
}

export default BaseRoutes;