import  './loader.css';
import loaderIMG from '../img/loader.svg';
import { useSelector } from 'react-redux';

const Loader = () => {

    const status = useSelector((state) => state.mainObject.loaderStatus);

    //console.log('status = ', status);

    return (
        <img src={loaderIMG} id="App-loader" className="App-loader" alt="loader" style={{display: status}}/>
    )
}

export default Loader;