import React, { useState, useEffect, useRef } from 'react';
import DataTable from "react-data-table-component";
import { getDictionary1Info, addNewRowDict1, editRowsDict1, deleteRowsDict1 } from '../../scripts/api/dictionary1Info.js';
import { createModal } from "react-prompt-modal";
import ModalWithAbort from "../../components/modalwithabort.js";
//import ModalWithProceed from "./examples/ModalWithProceed";
import ModalWithForm from "../../components/modalwithform.js";
import ModalWithFormEdit from "../../components/modalwithformedit.js";
import { getMessage } from '../../scripts/getmessage.js';
import { useDispatch, useSelector } from 'react-redux';
import { loader_On, loader_Off, set_toolbar1_state, set_toolbar2_state, dict1_set_data, dict1_get_data } from '../../redux/actions.js';


let rows = [];
let selectedRowsCurrent = [];
let columns = [
  {
    name: 'id', //getMessage('EN', 140),
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: 'descr',//getMessage('EN', 141),
    selector: (row) => row.descr,
    sortable: true,
  }
];

function Dictionary1({ obj, descr, tablename }) {

  const dispatch = useDispatch();
  const messagesItems = useSelector(state => state.mainObject.messageTranslateItems);
  const userName = useSelector(state => state.mainObject.userName);
  const data = useSelector(state => state.mainObject.dictionary1Data);

  console.log('dict1.data = ', data);

  const handleSearch = (e) => {
    let searchValue;
    let idValue;
    let descrValue;

    const newRows = rows.filter((row) => {
      idValue = row.id
        .toString()
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
      descrValue = row.descr
        .toLowerCase()
        .includes(e.target.value.toLowerCase());

      if (idValue) {
        searchValue = idValue;
      } else {
        searchValue = descrValue;
      }

      return searchValue;
    });

    dispatch(dict1_set_data({
      rows: newRows,
      toggledClearRows: false
    }));
  };

  const handleSelected = (data) => {
    selectedRowsCurrent = data.selectedRows;
  };

  const handleClearRows = () => {
    dispatch(dict1_set_data({
      rows: rows,
      toggledClearRows: !data.toggledClearRows
    }));
  }

  const calcDisplayHeight = () => {
    return 8;
  }

  const setToolBars = () => {
    dispatch(set_toolbar1_state({
      toolbarDescr: '136',
      buttonsItems: [
        { descr: 'Add', command: () => { handleModalWithForm(addNewRow) } },
        {
          descr: 'Edit', command: () => {
            if (selectedRowsCurrent.length > 0) {
              handleModalWithFormEdit(editSelectedRow, selectedRowsCurrent[0].id, selectedRowsCurrent[0].descr);
            }
          }
        },
        {
          descr: 'Delete', command: () => {
            if (selectedRowsCurrent.length > 0) {
              handleModalWithAbort(deleteTableRows);
            }
          }
        }
      ]
    }));
  }

  const setDictionaryInfo = (newData) => {
    loader_Off();
    dispatch(dict1_set_data({
      rows: newData,
      toggledClearRows: false
    }));
    setToolBars();
  }

  useEffect(() => {
    dispatch(loader_On());
    dispatch(dict1_get_data({
      userName: userName,
      dictionaryName: tablename,
      callback: setDictionaryInfo
    }));
  }, []);

  const deleteTableRows = async () => {
    /*rows = [];

    loaderOn();

    const di = await deleteRowsDict1(sessioninfo.userName, sessioninfo.menuItems.find(el => el.id === obj).tablename, selectedRowsCurrent);

    loaderOff();

    rows = Array.from(di);
    setData({
      tableName: sessioninfo.menuItems.find(el => el.id === obj).tablename,
      rows: rows,
      toggledClearRows: false
    });*/
  }

  const editSelectedRow = async (curID, newdescr) => {
    /*rows = [];

    loaderOn();

    const di = await editRowsDict1(sessioninfo.userName, sessioninfo.menuItems.find(el => el.id === obj).tablename, curID, newdescr);

    loaderOff();

    rows = Array.from(di);
    setData({
      tableName: sessioninfo.menuItems.find(el => el.id === obj).tablename,
      rows: rows,
      toggledClearRows: false
    });
    handleClearRows();*/
  }

  const addNewRow = async (newdescr) => {
    /*rows = [];

    loaderOn();

    const di = await addNewRowDict1(sessioninfo.userName, sessioninfo.menuItems.find(el => el.id === obj).tablename, newdescr);

    loaderOff();

    rows = Array.from(di);
    setData({
      tableName: sessioninfo.menuItems.find(el => el.id === obj).tablename,
      rows: rows,
      toggledClearRows: false
    });*/
  }

  const handleModalWithAbort = (command) => {
    /*const modal = createModal({
      sessionInfo: sessioninfo,
      component: ModalWithAbort,
      beforeClose: () => {
      },
      proceed: () => {
        modal.close();
        command();
      }
    });
    modal.open();*/
  };

  const handleModalWithForm = (command) => {
    /*const modal = createModal({
      sessionInfo: sessioninfo,
      component: ModalWithForm,
      proceed: ({ newdescr }) => {
        modal.close();
        command(newdescr);
      },
      tableName: sessioninfo.menuItems.find(el => el.id === obj).descr
    });
    modal.open();*/
  };

  const handleModalWithFormEdit = (command, curID, startValue) => {
    /*const modal = createModal({
      sessionInfo: sessioninfo,
      component: ModalWithFormEdit,
      proceed: ({ newdescr }) => {
        modal.close();
        command(curID, newdescr);
      },
      tableName: sessioninfo.menuItems.find(el => el.id === obj).descr,
      startValue: startValue
    });
    modal.open();*/
  };

  const handleDoubleClick = (propsDouble) => {
    handleModalWithFormEdit(editSelectedRow, propsDouble.id, propsDouble.descr);
  }

  function makeChanges(commandType = '') {

    switch (commandType) {
      case 'Add':
        handleModalWithForm(addNewRow);
        break;
      case 'Edit':
        if (selectedRowsCurrent.length > 0) {
          handleModalWithFormEdit(editSelectedRow, selectedRowsCurrent[0].id, selectedRowsCurrent[0].descr);
        }
        break;
      case 'Delete':
        if (selectedRowsCurrent.length > 0) {
          handleModalWithAbort(deleteTableRows);
        }
        break;
      default:
        break;
    }
  }

  /*  sessioninfo.newtext = "second";

  useEffect(() => {
    sessioninfo.toolBar1State(sessioninfo.toolBar1Buttons);
    sessioninfo.toolBar2State(sessioninfo.newtext);
    getDictionaryInfo();
  }, [sessioninfo.menuItems.find(el => el.id === obj).tablename, data.toggledClearRows]);
*/
  return (
    <>
      <div className="container my-5">
        <div className="input-group">
          <input
            type="search"
            className="form-control-sm border ps-3"
            placeholder={getMessage(messagesItems, 152)}
            onChange={handleSearch}
          />
        </div>
        <DataTable
          columns={columns}
          data={data.rows}
          fixedHeader
          title={descr}
          pagination
          responsive={false}
          selectableRows
          selectableRowsHighlight
          onSelectedRowsChange={handleSelected}
          clearSelectedRows={data.toggledClearRows}
          onRowDoubleClicked={handleDoubleClick}
          striped
          highlightOnHover
          paginationPerPage={calcDisplayHeight()}
          paginationRowsPerPageOptions={[1, 2, 4, 25, 30]}
        />
      </div>
    </>
  );
}

export default Dictionary1;