
import Dictionary1 from '../pages/dictionary1/dictionary1.js';
import Task2 from '../pages/task2/task2.js';
import Task3 from '../pages/task3/task3.js';
import BaseUsers from '../pages/baseusers/baseusers.js';
import Settings from '../pages/settings/settings.js';
import Tasks from '../pages/tasks/tasks.js';
import InfoPage from '../pages/settings/settings.js';

const WrapperComponent = ({component, obj, descr, tablename}) => {
    switch (component) {
        case 'Dictionary1':
            return (<Dictionary1 obj={obj} descr={descr} tablename={tablename} />);
        case 'Task2':
            return (<Task2 obj={obj} descr={descr} tablename={tablename} />);
        case 'Task3':
            return (<Task3 obj={obj} descr={descr} tablename={tablename} />);
        case 'BaseUsers':
            return (<BaseUsers obj={obj} descr={descr} tablename={tablename} />);
        case 'Settings':
            return (<Settings obj={obj} descr={descr} tablename={tablename} />);
        case 'Tasks':
            return (<Tasks obj={obj} descr={descr} tablename={tablename} />);
        case 'InfoPage':
            return (<InfoPage obj={obj} descr={descr} tablename={tablename} />);
        default:
            return (<></>);
    }
    return (<></>);
}

export default WrapperComponent;
