import './loginform.css';
import md5 from 'md5';
import { checkUserRegistration } from '../../scripts/api/checkuser.js';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loader_On, loader_Off, check_user, set_userinfo } from '../../redux/actions.js';
import { getMessage } from '../../scripts/getmessage.js';
import { Navigate } from 'react-router-dom';

const LoginForm = () => {

    const dispatch = useDispatch();
    const userInfo = useSelector(state => {
        return {
            userName: state.mainObject.userName,
            userPass: state.mainObject.userPass,
            remembermy: state.mainObject.remembermy,
            autorised: state.mainObject.autorised
        }
    });

    const messageItems = useSelector(state => state.mainObject.messageTranslateItems);

    function setUserInfo(newData) {
        dispatch(loader_Off());
        dispatch(set_userinfo(newData));
    }

    function checkUserProc(e) {
        e.preventDefault();
        dispatch(loader_On());
        const data = Object.fromEntries(new FormData(e.target));
        dispatch(check_user({
            userName: data.loginname,
            userPass: md5(data.loginpass),
            remembermy: data.remembermy,
            callback: setUserInfo
        }));
    }

    if (userInfo.autorised){
        return <Navigate to="/" replace/>;
    }

    return (
        <form className="loginpageform" onSubmit={checkUserProc}>
            <div className="loginpageloginbox">
                <fieldset className="loginpagefieldset">
                    <label className="loginpageloginmargin lightgray" htmlFor="loginname">{getMessage(messageItems, 116)}</label>
                    <br />
                    <input className="loginpageinputlogin  darkblue lightgray"
                        type="text"
                        id="loginname"
                        name="loginname"
                        required
                        defaultValue={userInfo.userName}
                    />
                </fieldset >
                <hr className="loginpageloginhr" />
                <fieldset className="loginpagefieldset">
                    <label className="lightgray" htmlFor="loginpass">{getMessage(messageItems, 117)}</label>
                    <br />
                    <input className="loginpageinputlogin darkblue lightgray"
                        type="password"
                        id="loginpass"
                        name="loginpass"
                        required
                        defaultValue={userInfo.userPass}
                    />
                </fieldset>
                <br />
                <button className="button loginpagesubmitmargin loginpageloginsubmitbutton blue lightgray" type="submit">{getMessage(messageItems, 153)}</button>
                <fieldset className="loginpagefieldset">
                    <div className="loginpageloginmargin darkgray">
                        <input type="checkbox" id="rememdermy" name="remembermy" defaultChecked={userInfo.remembermy} />
                        {getMessage(messageItems, 118)}
                    </div>
                </fieldset>
                <hr className="loginpagesubmitmargin1 loginpageloginhr darkgray" />
            </div>
        </form>
    )
}

export default LoginForm;