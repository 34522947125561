
export async function checkUserRegistration(payload) {

    let data = { username: '', message: '' };

    const setData = (response) => {
        data = { username: response.username, message: response.message }
    };

    await fetch(`http://93.170.216.66:3001/api/checkUserLogin?username=${payload.userName}&userpass=${payload.userPass}`)
        .then((response) => response.json())
        .then(response => setData(response));

    payload.callback({
        result: (payload.userName === data.username), order: {
            userName: payload.userName,
            userPass: '',
            remembermy: payload.remembermy
        }
    });
    return { logname: data.username };
}

export async function SaveUserInfo(payload) {

    let data = { username: '', message: '' };

    const setData = (response) => {
        data = { username: response.username, message: response.message }
    };

    await fetch(`http://93.170.216.66:3001/api/saveNewUserInfo?username=${payload.userName}&userpass=${payload.userPass}&userpass=${payload.userPass}&userpass=${payload.userPass}&userpass=${payload.userPass}`)
        .then((response) => response.json())
        .then(response => setData(response));

    payload.callback({
        result: false, order: {
            userName: payload.userName,
            userPass: '',
            remembermy: true
        }
    });
    return { logname: data.username };
}