
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions";
import { useEffect } from "react";

const Logout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(logout(1));
        navigate("/");
    }, []);
    
    return (<></>)

    return (
    <Navigate to='/login'/>
    )
}

export default Logout;