export async function getDictionary1Info(payload) {
    const res =  Array.from(await fetch(`http://93.170.216.66:3001/api/getDictionary1?username=${payload.userName}&tableName=${payload.dictionaryName}`)
        .then((response) => response.json())
        .then(response => response.dictionary));
    payload.callback(res);
    return res;
}

export async function deleteRowsDict1(userName, dictionaryName, rows) {
    return Array.from(await fetch(`http://93.170.216.66:3001/api/deleteRowsDict1?username=${userName}&tableName=${dictionaryName}
        &idarray=${rows.map(el=>`${el.id},`)}`)
        .then((response) => response.json())
        .then(response => response.dictionary));
}


export async function addNewRowDict1(userName, dictionaryName, newdescr) {
    return Array.from(await fetch(`http://93.170.216.66:3001/api/addNewRowDict1?username=${userName}&tableName=${dictionaryName}
        &newdescr=${newdescr}`)
        .then((response) => response.json())
        .then(response => response.dictionary));
}

export async function editRowsDict1(userName, dictionaryName, rowID,newdescr) {
    return Array.from(await fetch(`http://93.170.216.66:3001/api/editRowDict1?username=${userName}&tableName=${dictionaryName}
        &idrow=${rowID}&newdescr=${newdescr}`)
        .then((response) => response.json())
        .then(response => response.dictionary));
}

