import React, { useEffect, useMemo, useState } from 'react'
import TableBody from './tablebody.js';
import { useDispatch, useSelector } from 'react-redux';
import './basictable.css';
//import { getMessage } from '../../scripts/getmessage.js';

const maxlength = 30;

const BasicTable = () => {

    const messagesItem = useSelector((state) => state.mainObject.messageTranslateItems);
    const tableData = useSelector((state) => state.mainObject.task2Data);

    //console.log('BaseTable.tableData = ', tableData);
    //console.log('BaseTable.tableData.rows = ', tableData.rows);
    //console.log('BaseTable.tableData.rows.length = ', tableData.rows.length);

    if (tableData.rows.length === 0) return (<></>);

    let data = tableData.rows.filter(row => row.folder).map((row) => {
        return {
            id: row.id,
            descr: row.descr.substring(0, maxlength),
            city: row.city_descr.substring(0, maxlength),
            clientaddress: row.clientaddress.substring(0, maxlength),
            contact: row.contact.substring(0, maxlength),
            subRows: tableData.rows.filter(row1 => row1.parent === row.id).map(row1 => {
                return {
                    id: row1.id,
                    descr: row1.descr.substring(0, maxlength),
                    city: row1.city_descr.substring(0, maxlength),
                    clientaddress: row1.clientaddress.substring(0, maxlength),
                    contact: row1.contact.substring(0, maxlength),
                }
            }
            )
        };
    });

    let columns = [{
        id: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? '📂' : '📁'}
            </span>
        ),
        Cell: ({ row }) => (
            row.canExpand ? (
                <span
                    {...row.getToggleRowExpandedProps({
                        style: {
                            paddingLeft: `${row.depth * 2}rem`,
                        },
                    })}
                >
                    {row.isExpanded ? '📂' : '📁'}
                </span>
            ) : null
        ),
    }];

    Object.keys(data[0]).map((el) =>
        columns.push({
            Header: el.toUpperCase(),
            accessor: el,
            filter: 'includes',
            
        })
    );

    columns.pop();

    return (
        <div className="myTable">        
        <TableBody
            columnsstart={columns}
            datastart={data}
            dataorigin={tableData.rows}
        />
        </div>
    )

}

export default BasicTable;

