
import './buttons.css';
import { getMessage } from '../scripts/getmessage';
import { useSelector } from 'react-redux';

const Buttons = ({buttons}) => {

    const messagesItems = useSelector(state => state.mainObject.messageTranslateItems);

    return (
        <div>
            {
                buttons.map(element => (
                    <button key={element.descr} className='my-tool-button' onClick={() => { element.command(element.descr) }}>{getMessage(messagesItems, element.descr)}</button>
                ))
            }
        </div>
    )
}

export default Buttons;