
const InitObject = {
    loginDuration : 1000 * 3600 * 24 * 14,
    siteStorage : window.localStorage,
    loaderStatus : 'none',    
    messageTranslateLoaded : false,
    currentLanguage : 'EN',
    messageTranslateItems : [{
      id: 101,
      id_language: 'EN',
      message_id: '',
      message_translate: ''
    }],
    languages : [],
    currentRegistrationPage: 'signin',
    userName : 'Ivanov',
    userPass : 'Asdfgh',
    remembermy : false,
    autorised : true,    
    menuItems : [],
    menuLoaded : false,
    toolBar1State : {toolbarDescr: '136', buttonsItems:[]},
    toolBar2State : {toolbarDescr: '137', buttonsItems:[]},
    dictionary1Data : {rows: [], toggledClearRows: false},
    task2Data : {rows: []},



    mainRouterState : false,
    toolBar1Tick : false,
    toolBar2Tick : false,
    newtext : 'starttext',
    toolsReady : false,
    toolBar1Buttons : [],    
    registrationSetPage : false,
    currentPageSet : false,
    
}

export default InitObject;