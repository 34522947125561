import { NavLink } from 'react-router-dom';
import "./navbarmenu.css";
import SelectLanguage from "../../components/selectlanguage.js";
import { useDispatch, useSelector } from 'react-redux';
import { getMessage } from '../../scripts/getmessage';

const NavBarMenu = () => {

  const dispatch = useDispatch();
  const menuItems = useSelector(state => state.mainObject.menuItems);
  const messageItems = useSelector(state => state.mainObject.messageTranslateItems);

  return (
    <>
      <nav className="navbar navbar-expand bg-dark navbar-dark">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {menuItems.filter(el => el.parent === 0).map((el) => (
              (el.folder === true)
                ? <li key={el.id} className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {getMessage(messageItems, el.descr)}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {menuItems.filter(el1 => el1.parent === el.id).map(el1 => (
                      el1.separat === true
                        ? <li key={el1.id}><hr className="dropdown-divider " /></li>
                        : <li key={el1.id} className="dropdown-item ">
                          <NavLink to={el1.link} className="dropdown-link dropdown-item">
                            {getMessage(messageItems, el1.descr)}
                          </NavLink>
                        </li>
                    ))}
                  </ul>
                </li>
                : <li key={el.id} className="nav-item">
                  <NavLink to={el.link} className="nav-link">
                    {getMessage(messageItems, el.descr)}
                  </NavLink>
                </li>
            ))}
          </ul>
        </div>
        <SelectLanguage/>
        <form className="d-flex" role="search">
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button className="btn btn-outline-success my-search-button" type="submit">
          {getMessage(messageItems, 135)}
          </button>
        </form>
      </nav>
    </>
  )
}

export default NavBarMenu;