import { Routes, Route } from "react-router-dom";
import BasePage from '../pages/base/basepage.js';
import Registration from '../pages/registration/registration.js';
import Logout from './logout.js';
import ProtectedRoutes from "./protectedroutes.js";
import { useSelector } from 'react-redux';

const MainRoutes = () => {

    const userAutorised = useSelector(state => state.mainObject.autorised);
    
    return (
        <Routes>
            <Route path="/*" element={
                <ProtectedRoutes auth={userAutorised}>
                    <BasePage />
                </ProtectedRoutes>
            } />
            <Route path="/login" element={<Registration />} />
            <Route path="/logout" element={<Logout />} />
        </Routes>
    )
}

  export default MainRoutes;