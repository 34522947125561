import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { loader_On, loader_Off, dict1_get_data, dict1_set_data, set_toolbar1_state, set_toolbar2_state } from "../../redux/actions.js";
import BasicTable from '../../components/table/basictable.js';

const Task3 = () => {

  const dispatch = useDispatch();
  const userName = useSelector(state => state.mainObject.userName);

  function setTask3nfo(newData) {
    dispatch(loader_Off());
    dispatch(dict1_set_data(newData));
    dispatch(set_toolbar1_state({
      toolbarDescr: 136,
      buttonsItems: [
        { descr: 'NewTask', command: makeChanges },
        { descr: 'ExistTasks', command: makeChanges },
        { descr: 'AddFolder', command: makeChanges },
        { descr: 'EditFolder', command: makeChanges },
        { descr: 'DeleteFolder', command: makeChanges },
        { descr: 'Add', command: makeChanges },
        { descr: 'Edit', command: makeChanges },
        { descr: 'Delete', command: makeChanges }
      ]
    }));
  }

  const getTask3Info = async () => {
    dispatch(loader_On());
    dispatch(dict1_get_data({
      username: userName,
        dictionaryName: 'sp_tasktypes',
      callback: setTask3nfo
    }));    
  }

  useEffect(() => {
    getTask3Info();
  }, []);

  function makeChanges(commandType = '') {
    /*
            switch (commandType) {
                case 'AddFolder':
                handleModalWithForm(addNewRow);
                break;
              case 'EditFolder':
                if (selectedRowsCurrent.length > 0) {
                  handleModalWithFormEdit(editSelectedRow, selectedRowsCurrent[0].id, selectedRowsCurrent[0].descr);
                }
                break;
              case 'DeleteFolder':
                if (selectedRowsCurrent.length > 0) {
                  handleModalWithAbort(deleteTableRows);
                }
                break;
              case 'Add':
                handleModalWithForm(addNewRow);
                break;
              case 'Edit':
                if (selectedRowsCurrent.length > 0) {
                  handleModalWithFormEdit(editSelectedRow, selectedRowsCurrent[0].id, selectedRowsCurrent[0].descr);
                }
                break;
              case 'Delete':
                if (selectedRowsCurrent.length > 0) {
                  handleModalWithAbort(deleteTableRows);
                }
                break;
              default:
                break;
            }*/
  }

  return (
    <BasicTable />
  )
}

export default Task3;