import './logobox.css';
import Logo_Signin from "../../img/logo_signin.png";
import { getMessage } from '../../scripts/getmessage.js';
import { useSelector, useDispatch } from 'react-redux';
  
const LogoBox = () => {

    const messageItems = useSelector(state => state.mainObject.messageTranslateItems);
    
    return (
        <div className="loginpagelogobox">
            <img className="loginpagelogo" src={Logo_Signin} alt="logo" />
            <br />
            <h2 className="loginpageservername">{getMessage(messageItems, 115)}</h2>
            
        </div>
    )
}

export default LogoBox;