import {
  NONE, INIT, 
  LOADER_ON, LOADER_OFF,
  LOAD_MESSAGES, SET_MESSAGES, CHANGE_LOGIN_PAGE, CHANGE_LANGUAGE,    
  SET_USERINFO, CHECK_USER, SAVE_USER, LOGOUT,
  LOAD_MENU, SET_MENU,
  SET_TOOLBAR1_STATE, SET_TOOLBAR2_STATE,
  DICT1_SET_DATA, DICT1_GET_DATA,
  TASK2_SET_DATA, TASK2_GET_DATA,
  ADD_MESSAGES} from './types'; 

export const init = (value) => {
  console.log('INIT');
  return { type: INIT, payload: value };
};

export const loader_On = (value='') => {
  return { type: LOADER_ON, payload: 'flex' };
};

export const loader_Off = (value='') => {
  return { type: LOADER_OFF, payload: 'none'};
};

export const load_messages = (value) => {
  return { type: LOAD_MESSAGES, payload: value };
};

export const set_messages = (value) => {
  return { type: SET_MESSAGES, payload: value };
};

export const change_language = (value) => {
  return { type: CHANGE_LANGUAGE, payload: value };
};

export const change_login_page = (value) => {
  return {type: CHANGE_LOGIN_PAGE, payload: value}
}

export const check_user = (value) => {
  return { type: CHECK_USER, payload: value };
};

export const set_userinfo = (value) => {
  return { type: SET_USERINFO, payload: value };
};

export const save_user = (value) => {
  return { type: SAVE_USER, payload: value };
};

export const logout = (value='') => {
  return { type: LOGOUT, payload: value }
}

export const load_menu = (value) => {
  return { type: LOAD_MENU, payload: value };
};

export const set_menu = (value) => {
  return { type: SET_MENU, payload: value };
};

export const set_toolbar1_state = (value) => {
  return { type: SET_TOOLBAR1_STATE, payload: value };
};

export const set_toolbar2_state = (value) => {
  return { type: SET_TOOLBAR2_STATE, payload: value };
};

export const dict1_set_data = (value) => {
  return { type: DICT1_SET_DATA, payload: value };
};

export const dict1_get_data = (value) => {
  return { type: DICT1_GET_DATA, payload: value };
};

export const task2_set_data = (value) => {
  return { type: TASK2_SET_DATA, payload: value };
};

export const task2_get_data = (value) => {
  return { type: TASK2_GET_DATA, payload: value };
};
