import React, { useEffect, useMemo, useState, useRef, forwardRef, useCallback } from 'react'
import {
    useTable,
    useSortBy,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    Row,
    TableToggleAllRowsSelectedProps,
    useExpanded
} from 'react-table';
import './basictable.css';
import { GlobalFilterInput } from './globalfilterinput';
import Pagination from './pagination';

let tableData = [];
let headers = [];
let columns = [];
let data = [];
let pageIndex = 0;
const showRows = [10, 20, 30, 40];
let pageSize = showRows[0];

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

const TableBody = ({ columnsstart, datastart, dataorigin }) => {

    const myGlobalFilter = useCallback((rows, ids, query) => {
        const searchTerm = String(query);

        //console.log('myfilter.rows', rows);
        //console.log('myfilter.ids', ids);
        //console.log('myfilter.query', query);

        const result = [];

        rows.forEach(row => {
            const matches = ids.filter((id) => {
                const rowValue = row.values[id];
                let searchTermLower = searchTerm.toLowerCase();

                if (rowValue !== undefined) {
                    if (String(rowValue).toLowerCase().includes(searchTermLower.toLowerCase())) {
                        result.push(row);
                    }
                }                
            });
            row.subRows.forEach(row1 => {
                const matches = ids.filter((id) => {
                    const rowValue = row1.values[id];
                    let searchTermLower = searchTerm.toLowerCase();

                    if (rowValue !== undefined) {
                        if (String(rowValue).toLowerCase().includes(searchTermLower.toLowerCase())) {
                            result.push(row1);
                        }
                    }
                });
            });
        });

        console.log('result = ', result);

        return result;

    }, []);


    columns = columnsstart;
    data = datastart;

    function getMyHeaderProps(headerProps) {
        return headerProps
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        canExpand,
        pageCount,
        gotoPage,
        setPageSize,
        selectedFlatRows,
        state: { globalFilter, selectedRowIds, expanded }
    } = useTable({
        columns,
        data,
        initialState: {
            pageSize: pageSize,
            pageIndex: pageIndex
        },
        globalFilter: myGlobalFilter
    },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <><div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                        </>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    );

    const getTitle = (row, pos, cell) => {
        let result = '';

        switch (pos) {
            case 3:
                result = dataorigin.find(roworigin => roworigin.id === row.values.id).descr;
                break;
            case 5:
                result = dataorigin.find(roworigin => roworigin.id === row.values.id).clientaddress;
                break;
            case 6:
                result = dataorigin.find(roworigin => roworigin.id === row.values.id).contact;
                break;
            default:
                break;
        }
        return result;
    }

    return (
        <>
            <GlobalFilterInput
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
            />
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr key={`header_row_${i}`} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, j) => (
                                <th key={`header_column_${i}_${j}`} {...getMyHeaderProps(column.getHeaderProps(column.getSortByToggleProps()))}>{column.render('Header')}
                                    {/*<div>{column.canFilter ? column.render('Filter') : null}</div>*/}
                                    <span>
                                        {column.isSorted
                                            ? (column.isSortedDesc
                                                ? <img draggable="false" role="img" className="emoji" alt="▼" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f53d.svg" />
                                                : <img draggable="false" role="img" className="emoji" alt="▲" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f53c.svg" />)
                                            : <></>}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        page.slice(0, 10).map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr key={`body_row_${i}`} {...row.getRowProps()}>
                                    {row.cells.map((cell, j) => {
                                        return <td key={`body_column_${i}_${j}`} {...cell.getCellProps()} title={getTitle(row, j, cell)}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div>
                {/* пагинация */}
                <Pagination
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageCount={pageCount}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    showRows={showRows}
                    selectedRowIds={selectedRowIds}
                    rowslength={rows.length}
                    expanded={expanded}
                />
            </div>
        </>
    )
}

export default TableBody;