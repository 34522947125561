import NavBarMenu from './navbarmenu.js';
import ToolBar1 from './toolbar1.js';
import ToolBar2 from './toolbar2.js';
import AllFooter from '../allfooter.js';
import './basepage.css';
import { useEffect } from 'react';
import BaseRouter from '../../components/baserouter.js'
import { loader_On, loader_Off, load_menu, set_menu } from '../../redux/actions.js';
import { useSelector, useDispatch } from 'react-redux';


const BasePage = () => {


  const dispatch = useDispatch();
  const menuItems = useSelector(state => state.mainObject.menuItems);
  const userName = useSelector(state => state.mainObject.userName);
  

  function setMenu(newData) {    
    dispatch(loader_Off());
    dispatch(set_menu(newData));
  }

  useEffect(() => {
    if (menuItems === undefined || menuItems.length < 2) {
      dispatch(loader_On());
      dispatch(load_menu({ userName: userName, callback: setMenu }));
    };
  }, []);

/*
  const [menuItems, setMenuItems] = useState([]);

  async function getMenuItems () {

    loaderOn();

    const mi = await getMenuStructure();

    loaderOff();

    setMenuItems(Array.from(mi));
    
    if (mi.length > 0 && mi[0] !== '<') {
      sessioninfo.menuItems = Array.from(mi);
      sessioninfo.menuLoaded = true;    
      sessioninfo.storage.setItem('menuItemsCount', mi.length);
      mi.forEach((el, i) => {
        sessioninfo.storage.setItem(`menuItem${i}.id`, el.id);
        sessioninfo.storage.setItem(`menuItem${i}.folder`, el.folder);	
        sessioninfo.storage.setItem(`menuItem${i}.parent`, el.parent);	
        sessioninfo.storage.setItem(`menuItem${i}.separat`, el.separat);	
        sessioninfo.storage.setItem(`menuItem${i}.descr`, el.descr);	
        sessioninfo.storage.setItem(`menuItem${i}.hoverdescr`, el.hoverdescr);	
        sessioninfo.storage.setItem(`menuItem${i}.icon`, el.icon);	
        sessioninfo.storage.setItem(`menuItem${i}.link`, el.link);	
        sessioninfo.storage.setItem(`menuItem${i}.form`, el.form);
        sessioninfo.storage.setItem(`menuItem${i}.tablename`, el.tablename);
      });
    }
  }

  useEffect(() => {
    if (!sessioninfo.menuLoaded) {
      getMenuItems();
    } else {
      setMenuItems(Array.from(sessioninfo.menuItems));
      setTimeout(getMenuItems, 50000);
    }
  }, []
  );

  if (!sessioninfo.userAutorised) {
    return <Navigate to='/login' replace />
  }
*/
  return (
    <div>
      <NavBarMenu />
      <div className="basepagework">
        <ToolBar1 />
        <main className="col-10 basepagewindow" id="basepagewindow">
          <BaseRouter />
        </main>
        <ToolBar2 />
      </div>
      <AllFooter />
    </div>
  )
}

export default BasePage;