import './signup.css';

import md5 from 'md5';
import { useSelector, useDispatch } from 'react-redux';
import { getMessage } from '../../scripts/getmessage';
import { loader_On, loader_Off, save_user, set_userinfo } from '../../redux/actions';
import { Navigate } from 'react-router-dom';

const SignUP = () => {

    let saved = false;
    const dispatch = useDispatch();
    const messagesItems = useSelector(state => state.mainObject.messageTranslateItems);
    const userInfo = useSelector(state => {
        return {
            userName: state.mainObject.userName,
            userPass: state.mainObject.userPass,
            remembermy: state.mainObject.remembermy,
            autorised: state.mainObject.autorised
        }
    });

    function setUserInfo(newData) {
        dispatch(loader_Off());
        dispatch(set_userinfo(newData));
        saved = true;
    }

    function sendNewUser(e) {
        e.preventDefault();
        dispatch(loader_On());
        const data = Object.fromEntries(new FormData(e.target));
        dispatch(save_user({
            userName: data.newuser_loginname,
            userPass: md5(data.newuser_loginpass),
            userOwnName: data.newuser_name,
            email: data.newuser_email,
            phone: data.newuser_phone,
            callback: setUserInfo
        }));
    }

    if (saved) return <Navigate to="/login" replace />;

    return (
        <div className="loginpagemaininfo">
            <form onSubmit={sendNewUser}>
                <label htmlFor="newuser_loginname">{getMessage(messagesItems, 121)}</label>
                <input id="newuser_loginname" name="newuser_loginname" type="text" defaultValue=''></input>
                <br />
                <label htmlFor="newuser_name">{getMessage(messagesItems, 122)}</label>
                <input id="newuser_name" name="newuser_name" type="text" defaultValue=''></input>
                <br />
                <label htmlFor="newuser_email">{getMessage(messagesItems, 123)}</label>
                <input id="newuser_email" name="newuser_email" type="text" defaultValue=''></input>
                <br />
                <label htmlFor="newuser_phone">{getMessage(messagesItems, 124)}</label>
                <input id="newuser_phone" name="newuser_phone" type="text" defaultValue=''></input>
                <br />
                <label htmlFor="newuser_loginpass">{getMessage(messagesItems, 125)}</label>
                <input id="newuser_loginpass" name="newuser_loginpass" type="password" defaultValue=''></input>
                <br />
                <button type="submit">{getMessage(messagesItems, 126)}</button>
            </form>
        </div>
    )
}

export default SignUP;