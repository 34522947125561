import React, {useState, useEffect} from 'react';
import SelectLanguage from "../../components/selectlanguage.js";

import LogoBox from './logobox.js';
import LoginForm from './loginform.js';
import LoginFooter from './loginfooter.js';
import LoginMain from './loginmain.js';
import SignUP from './signup.js';
import AllFooter from '../allfooter.js';
import { loaderOff, checkLoader, loaderOn } from '../../components/loadercontrol.js';
import './registration.css';
import { useSelector, useDispatch } from 'react-redux';
import { loader_On, loader_Off } from '../../redux/actions.js'; 

const Registration = () => {


    const currentRegistrationPage = useSelector(state => state.mainObject.currentRegistrationPage);

    return (
        <div className="loginpageregisterrow">
            <aside className="loginpagecol-6 darkblue">
                <LogoBox />
                <SelectLanguage/>
                <LoginForm />
                <LoginFooter />
            </aside>
            {<main className="loginpagecol lightyellow">
                {
                    currentRegistrationPage === 'signin'
                        ? <LoginMain />
                        : <SignUP />
                }
                <AllFooter />
            </main>}
        </div>
    )
}

export default Registration;