import { useSelector, useDispatch } from 'react-redux';
import './selectlanguage.css';
import { change_language, set_messages, loader_On, loader_Off, load_messages } from '../redux/actions.js';

const SelectLanguage = () => {

  const dispatch = useDispatch();
  const languagesInfo = useSelector(state => {
    return {
      currentLanguage: state.mainObject.currentLanguage,
      languages: state.mainObject.languages
    }
  });

  function setMessages(newData) {
    dispatch(loader_Off());
    dispatch(set_messages(newData));
  }

  function handleOptionChange(changeEvent) {
    dispatch(loader_On());
    dispatch(change_language({ lang: changeEvent.target.value, callback: setMessages }));   
  }

  return (
    <div className="languages">
      {languagesInfo.languages.map((el, i) => (
        <div key={'div-'+ i} className='radio'>
          {
            <input key={'language-' + i} className='selectlanguages-input' type='radio' hidden name='language' id={'language-' + i} onChange={handleOptionChange} defaultChecked={el === languagesInfo.currentLanguage} value={el} />
          }
          <label key={'label-' + i} className='selectlanguages-label' htmlFor={"language-" + i}><div>{el}</div></label>
        </div>
      ))}
    </div>
  )
}

export default SelectLanguage;