import React, { useState, useEffect } from 'react'
import './App.css';
import Loader from './components/loader';
import MainRoutes from './components/mainroutes.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { load_messages, set_messages, loader_On, loader_Off } from './redux/actions.js';

const App = () => {

  const dispatch = useDispatch();
  const messageItems = useSelector(state => {
    return {
      currentLanguage: state.mainObject.currentLanguage,
      messageTranslateItems: state.mainObject.messageTranslateItems
    }
  });

  function setMessages(newData) {    
    dispatch(loader_Off());
    dispatch(set_messages(newData));
  }

  useEffect(() => {
    if (messageItems.messageTranslateItems === undefined || messageItems.messageTranslateItems.length < 2) {
      dispatch(loader_On());
      dispatch(load_messages({ lang: messageItems.currentLanguage, callback: setMessages }));
    };
  }, []);

  return (
    <div className='App'>
      <Loader />
      {
        (messageItems.messageTranslateItems.length > 2)
          ?
          <MainRoutes />
          : <></>
      }
    </div>
  )
}

export default App;