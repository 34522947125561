import React, {ChangeEventHandler} from "react"
import { useSelector } from "react-redux"
import { getMessage } from "../../scripts/getmessage"

export default function Pagination(props) {

  const messagesItem = useSelector((state) => state.mainObject.messageTranslateItems);
    // метод перехода к первой странице
    const gotoFirstPage = () => props.gotoPage(0)
    // метод перехода к последней странице
    const gotoLastPage = () => props.gotoPage(props.pageCount - 1)
    // метод перехода к указанной странице
    /*
    const gotoPage: ChangeEventHandler<HTMLInputElement> = (e) => {
      // индекс массива
      const page = e.target.value ? Number(e.target.value) - 1 : 0
      props.gotoPage(page)
    }
    // метод установки размера страницы
    const setPageSize: ChangeEventHandler<HTMLSelectElement> = (e) => {
      const size = Number(e.target.value)
      props.setPageSize(size)
    }
*/
    const gotoPage = (e) => {
        // индекс массива
        const page = e.target.value ? Number(e.target.value) - 1 : 0
        props.gotoPage(page)
      }
      // метод установки размера страницы
      const setPageSize = (e) => {
        const size = Number(e.target.value)
        props.setPageSize(size)
      }
  
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '10px',
          padding: '0 1rem'
        }}
      >
        <span>
          <button onClick={gotoFirstPage} disabled={!props.canPreviousPage}>
            {'<<'}
          </button>
          <button onClick={props.previousPage} disabled={!props.canPreviousPage}>
            {'<'}
          </button>
          <button onClick={props.nextPage} disabled={!props.canNextPage}>
            {'>'}
          </button>
          <button onClick={gotoLastPage} disabled={!props.canNextPage}>
            {'>>'}
          </button>
        </span>
        <span>
        {getMessage(messagesItem, 157)} {props.pageIndex + 1} of {props.pageCount}
        </span>
        <label>
        {getMessage(messagesItem, 158)}:{' '}
          <input
            type='number'
            defaultValue={props.pageIndex + 1}
            onChange={gotoPage}
            style={{ width: '8ch' }}
          />
        </label>
        <p>{`${props.rowslength} ${getMessage(messagesItem, 160)}`}</p>
        <select value={props.pageSize} onChange={setPageSize}>
          {props.showRows.map((size) => (
            <option value={size} key={size}>
              {getMessage(messagesItem, 156)} {size}
            </option>
          ))}
        </select>
        <p>{getMessage(messagesItem, 159)}: {Object.keys(props.selectedRowIds).length}</p>
      </div>
    )
  }