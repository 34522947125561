import { useEffect } from "react";
const Settings = (props) => {
    props.sessioninfo.toolBar1Buttons = [];
    useEffect(() => {
        props.sessioninfo.toolBar1State(props.sessioninfo.toolBar1Buttons);
      }, []);
    return (
        <div>Page for Settings / {props.obj}!!!</div>
    )
}

export default Settings;