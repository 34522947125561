
import './centralbox.css';

const CentralBox = (props) => {

    return (
        <></>
    )
}

export default CentralBox;