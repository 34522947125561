import "./toolbar2.css";
import Buttons from '../../components/buttons.js';
import { getMessage } from "../../scripts/getmessage";
import { useDispatch, useSelector } from "react-redux";

const ToolBar2 = () => {

    const messagesItems = useSelector(state => state.mainObject.messageTranslateItems);
    const toolBarState = useSelector(state => state.mainObject.toolBar2State);

    return (
        <aside className="col-1 my-table-navigation-1">
            <div>{getMessage(messagesItems, toolBarState.toolbarDescr)}</div>
            <Buttons buttons = {toolBarState.buttonsItems} />
        </aside>
    )
}

export default ToolBar2;