import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { useSelector } from "react-redux"
import { getMessage } from "../../scripts/getmessage.js"

export function GlobalFilterInput({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) {

  const messagesItem = useSelector((state) => state.mainObject.messageTranslateItems);

  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      {getMessage(messagesItem, 155)}: {''}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} ${getMessage(messagesItem, 154)}...`}
      />
    </span>
  );
}
