import {
    NONE, INIT,
    LOADER_ON, LOADER_OFF,
    LOAD_MESSAGES, SET_MESSAGES, CHANGE_LANGUAGE,
    SET_USERINFO, CHECK_USER, SAVE_USER, LOGOUT,
    CHANGE_LOGIN_PAGE,
    LOAD_MENU, SET_MENU,
    SET_TOOLBAR1_STATE, SET_TOOLBAR2_STATE,
    DICT1_SET_DATA, DICT1_GET_DATA,
    TASK2_SET_DATA, TASK2_GET_DATA,
} from './types';
import { getAllMessage } from '../scripts/api/getallmessage.js';
import { checkUserRegistration, SaveUserInfo } from '../scripts/api/checkuser.js';
import { getMenuStructure } from '../scripts/api/menu.js';
import { combineReducers } from 'redux';
import InitObject from './initobject';
import { getDictionary1Info } from '../scripts/api/dictionary1Info';
import { getTask2ClientsList } from '../scripts/api/task2Info';

async function getMessageItems(payload) {
    return await getAllMessage(payload);
}

async function checkUserRegistration1(payload) {
    return await checkUserRegistration(payload);
}

async function getMenuStructure1(payload) {
    return await getMenuStructure(payload);
}

async function saveNewUserInfo(payload) {
    return await SaveUserInfo(payload);
}

async function getDictionary1Info1(payload) {
    return await getDictionary1Info(payload);
}

async function getTask2Info(payload) {
    return await getTask2ClientsList(payload);
}

const store = (state = InitObject, { type = NONE, payload = undefined }) => {

    console.log('Reducer: action.type = ', type);

    switch (type) {
        case INIT:
            break
        case LOADER_ON:
            return { ...state, loaderStatus: payload }
        case LOADER_OFF:
            return { ...state, loaderStatus: payload }
        case LOAD_MESSAGES:
            const newMessages_LOAD_MESSAGES = getMessageItems(payload);
            return state;
        case SET_MESSAGES:
            const temp_SET_MESSAGES = payload.lang_list.split('/');
            temp_SET_MESSAGES.pop();
            return {
                ...state,
                messageTranslateItems: Array.from(payload.message_translate),
                languages: Array.from(temp_SET_MESSAGES),
                messageTranslateLoaded: true,
            }
        case CHANGE_LANGUAGE:
            const newMessages_CHANGE_LANGUAGE = getMessageItems(payload);
            return {
                ...state,
                currentLanguage: payload.lang,
            }
        case CHANGE_LOGIN_PAGE:
            return { ...state, currentRegistrationPage: payload }
        case CHECK_USER:
            const newUserInfo = checkUserRegistration1(payload);
            return state;
        case SET_USERINFO:
            return {
                ...state,
                userName: payload.order.userName,
                userPass: '',
                remembermy: payload.order.remembermy,
                autorised: payload.result
            };
        case SAVE_USER:
            const savedUserInfo = saveNewUserInfo(payload);
            return { state };
        case LOGOUT:
            return {...state, autorised: false}
        case LOAD_MENU:
            const newMenuLoad = getMenuStructure1(payload);
            return state;
        case SET_MENU:
            return {
                ...state,
                menuItems: Array.from(payload),
                menuLoaded: true
            };
        case SET_TOOLBAR1_STATE:
            return {
                ...state,
                toolBar1State: {
                    toolbarDescr: payload.toolbarDescr,
                    buttonsItems: payload.buttonsItems
                }
            }
        case SET_TOOLBAR2_STATE:
            return {
                ...state,
                toolBar2State: {
                    toolbarDescr: payload.toolbarDescr,
                    buttonsItems: payload.buttonsItems
                }
            }
        case DICT1_SET_DATA:
            return { ...state, dictionary1Data: payload };
            //return { ...state, task2Data: { rows: payload } };
        case DICT1_GET_DATA:
            const newDict1Data = getDictionary1Info1(payload);
            return state;
        case TASK2_SET_DATA:            
            return { ...state, task2Data: { rows: payload } };
        case TASK2_GET_DATA:
            const newTask2Data = getTask2Info(payload);
            return state;
        /*
    const newMessages = getMessageItems(payload);
        return state;
        const result = getMessageItems(action.value);
        if (result && result.length > 2) {
            state.messageTranslateItems = Array.from(result)
        }
        console.log('state.messageTranslateItems', state.messageTranslateItems);
        if (state.messageTranslateItems.length > 2) {
            state.languages = state.getMessage(100).split('/');
            state.languages.pop();
            state.saveMessageItems();
            state.messageTranslateLoaded = true;
        }*/
        /*
    case ADD_MENU:
        state.menuItems = Array.from(action.value);
        state.saveMenuItems();
        break;
    case CHECK_LANGUAGE:
            if (state.messageTranslateItems.length > 2) {
                state.languages = state.getMessage(100).split('/');
                state.languages.pop();                    
                state.saveMessageItems();
            }
            break
    
        break*/
        default:
            return state;
    }
}

const reducer = combineReducers({
    mainObject: store,
});

export default reducer;
