import './loginfooter.css';
import { useSelector, useDispatch } from 'react-redux';
import { getMessage } from '../../scripts/getmessage.js';
import { change_login_page } from '../../redux/actions';

const LoginFooter = () => {

    const dispatch = useDispatch();
    const messageItems = useSelector(state => state.mainObject.messageTranslateItems);
    
    function signUp() {
        dispatch(change_login_page('signup'));
    }

    return (
        <footer className="loginpagecontactinfofooter darkblue">
            <button className="loginpagecontactmargin loginpagecontacttext darkgray" onClick={signUp}>{getMessage(messageItems, 119)}</button>
            <br />
            <div className="loginpagecontactmargin1 loginpagecontacttext1 "><a className="darkgray" href="../pages/contactpage.html">{getMessage(messageItems, 120)}</a></div>
        </footer>
    )
}

export default LoginFooter;