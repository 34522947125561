export const NONE = 'NONE';
export const INIT = 'INIT';
export const LOADER_ON = 'LOADER_ON';
export const LOADER_OFF = 'LOADER_OFF';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const SET_MESSAGES = 'SET_MESSAGES';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LOGIN_PAGE = 'CHANGE_LOGIN_PAGE';
export const SET_USERINFO = 'SET_USERINFO';
export const CHECK_USER = 'CHECK_USER';
export const LOAD_MENU = 'ADD_MESSAGES';
export const SET_MENU = 'SET_MENU';
export const SAVE_USER = 'SAVE_USER';
export const LOGOUT = 'LOGOUT';
export const SET_TOOLBAR1_STATE = 'SET_TOOLBAR1_STATE';
export const SET_TOOLBAR2_STATE = 'SET_TOOLBAR2_STATE';
export const DICT1_SET_DATA = 'DICT1_SET_DATA';
export const DICT1_GET_DATA = 'DICT1_GET_DATA';
export const TASK2_GET_DATA = 'TASK2_GET_DATA';
export const TASK2_SET_DATA = 'TASK2_SET_DATA';



export const CHECK_LANGUAGE = 'CHECK_LANGUAGE';