import './loginmain.css';
import { useSelector, useDispatch } from 'react-redux';
import { getMessage } from '../../scripts/getmessage.js';

const LoginMain = () => {
    const messageItems = useSelector(state => state.mainObject.messageTranslateItems);

    return (
        <div className="loginpagemaininfo">
            <h2 className="loginpageinfotext loginpageinfotextheight">{getMessage(messageItems, 130)}</h2>
            <div className="loginpageinfotext1 loginpageinfotextheight1">
                {getMessage(messageItems, 131)}
            </div>
        </div>
    )
}

export default LoginMain;