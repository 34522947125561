
import { useSelector, useDispatch } from 'react-redux';
import { getMessage } from '../scripts/getmessage.js';

const AllFooter = () => {

    const messageItems = useSelector(state => state.mainObject.messageTranslateItems);
    
    return (
        <footer className="allpagecopyright lightgray">
            {getMessage(messageItems, 132)} <span>&copy;</span>{getMessage(messageItems, 133)}<span>&trade;</span>
            <br />
            <a href="../pages/contactus.html">{getMessage(messageItems, 134)}</a>
        </footer>
    )
}

export default AllFooter;